<template>
</template>
<script>
export default {
    beforeCreate(){
        let { type } = this.$route.query;

        switch(type){
            case "gift": {
                this.$router.push({
                    path: "/shop/gift-result",
                    query: this.$route.query
                })
                break;
            }
            default: {
                this.$router.push({
                    path: "/shop/result",
                    query: this.$route.query
                });
                break;
            }
        }
    },
}
</script>